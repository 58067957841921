.logoImage {
    width: 100%;
    max-height: 300px;
}

.siteTitle {
    font-size: 40px;
}
.siteDescription {
    font-size: 20px;
}

.siteRow {
    padding-top: 10px;
    padding-bottom: 10px;
}
.siteAlternateRow {
    /*background-color: #eee;*/
}

.footer {
    margin-top: 15px;
    padding-top: 10px;
    background-color: #eee;
}
@media only screen and (max-width: 768px) {
    .siteAlternateRow {
        background-color: #eee;
    }
}
#content {
    font-size: 40px;
    display: block;
}

.thin {
    font-weight: 100;
}
.regular {
}
.light {
    font-weight: 300;
}

body {
    font-family: 'Roboto', sans-serif;
    /*background-color: #000;*/
    /*color: #fff;*/
}
#container {
    height: 100%;
    width: 100%;
    display: flex;
    position: fixed;
    align-items: center;
    justify-content: center;
}